<template>
  <div>
    <p v-if="!isLittleScroll" class="label-12 mb-1">Client</p>
    <b-dropdown
      aria-role="menu"
      expanded
      append-to-body
      :close-on-click="false"
    >
      <template #trigger>
        <div
          class="input-search-2 flex-center adjust-input-clients border-radius-5"
        >
          <div class="vendor-filter-icon">
            <VendorFilter />
          </div>
          <b-input
            :value="selectedClients"
            placeholder="Semua Client"
            class="input-none c-w-100"
            readonly
          >
          </b-input>
        </div>
      </template>

      <div class="item-dropdown-client-wrapper">
        <ItemListDropdown
          :datas="mostSearched"
          title="Paling Sering Dicari"
          idItem="vendor_id"
          nameItem="vendor_name"
          logoItem="logo"
          class="mb-3"
          @handleSelectData="handleSelectData"
        />

        <ItemListDropdown
          :datas="clients"
          title="Pilih Client"
          idItem="vendor_id"
          nameItem="vendor_name"
          logoItem="logo"
          @handleSelectData="handleSelectData"
        />
      </div>
    </b-dropdown>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { alertErrorMessage } from '@/helpers'

export default {
  name: 'DropdownClients',
  props: {
    isLittleScroll: {
      type: Boolean,
      default: false
    }
  },
  components: {
    VendorFilter: () => import('@/components/icons/VendorFilter.vue'),
    ItemListDropdown: () => import('./ItemListDropdown.vue')
  },
  data () {
    return {
      searchQuery: '',
      clients: [],
      mostSearched: []
    }
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl'
    }),
    selectedClients () {
      const mergedArray = [...this.clients, ...this.mostSearched].reduce((acc, item) => {
        acc.set(item.vendor_id, item)
        return acc
      }, new Map())
      const result = Array.from(mergedArray.values())

      if (result.filter((item) => item.selected).length < 1) return 'Semua Client'
      return result
        .filter((item) => item.selected)
        .map((item) => item.vendor_name)
        .join(', ')
    }
  },
  methods: {
    updateSelection (arrayName, props) {
      this[arrayName] = this[arrayName].map(item =>
        item.vendor_id === props.vendor_id
          ? { ...item, selected: props.selected }
          : item
      )
    },
    handleSelectData (props) {
      const dataSelected = props.selected
      this.$store.commit('report/setSelectedClients', dataSelected)
      if (this.clients.some(item => item.vendor_id === dataSelected.vendor_id)) {
        this.updateSelection('clients', dataSelected)
      } else if (this.mostSearched.some(item => item.vendor_id === dataSelected.vendor_id)) {
        this.updateSelection('mostSearched', dataSelected)
      }

      const mergedArray = [...this.clients, ...this.mostSearched].reduce((acc, item) => {
        acc.set(item.vendor_id, item)
        return acc
      }, new Map())
      const temp = Array.from(mergedArray.values())
      const result = temp
        .filter((item) => item.selected)
        .map((item) => item.vendor_id)
        .join(',')

      this.$emit('selected', { data: result, type: 'clients' })
    },
    getListClients () {
      const payload = {
        url: this.url
      }
      this.$store
        .dispatch('report/getListClients', payload)
        .then((response) => {
          const res = response.data.data
          const resClients = res.vendors
          const resMostSearched = res.most_searched

          if (resClients.length > 0) {
            this.clients = resClients.map((item) => ({
              ...item,
              selected: false,
              list_stores: item.list_stores.map(store => ({ ...store, selected: false }))
            }))
          }
          if (resMostSearched.length > 0) {
            this.mostSearched = resMostSearched.map((item) => ({
              ...item,
              selected: false,
              list_stores: item.list_stores.map(store => ({ ...store, selected: false }))
            }))
          }
        })
        .catch((error) => {
          alertErrorMessage(error)
        })
    }
  },
  mounted () {
    this.getListClients()
  }
}
</script>

<style lang="scss" scoped>
@import "@/scss/_variables.scss";

.adjust-input-clients {
  padding: 2.4px;
  padding-left: 20px;
  position: relative;
  width: 230px;
}

.vendor-filter-icon {
  position: absolute;
  left: 5px;
  top: 9.5px;
  z-index: 2;
}

.item-dropdown-client-wrapper {
  overflow: auto;
  max-height: 280px;
}
</style>
